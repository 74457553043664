import type { SVGProps } from 'react';

const SvgGear = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.07 20h1.86c.764 0 1.363-.47 1.538-1.198l.377-1.631.24-.093 1.418.876c.644.405 1.4.304 1.933-.24l1.29-1.29c.552-.544.644-1.3.239-1.935l-.875-1.41.083-.231 1.63-.378A1.553 1.553 0 0 0 20 10.93V9.116c0-.756-.47-1.355-1.197-1.539l-1.611-.387-.093-.24.875-1.41c.405-.636.304-1.382-.24-1.945l-1.288-1.29c-.534-.534-1.29-.636-1.925-.24l-1.418.867-.258-.102-.377-1.63C12.293.47 11.694 0 10.93 0H9.07c-.774 0-1.363.47-1.538 1.198L7.155 2.83l-.258.102-1.418-.866c-.645-.397-1.4-.295-1.934.24l-1.289 1.29c-.543.562-.645 1.308-.24 1.944l.885 1.41-.102.24-1.602.387C.46 7.76 0 8.359 0 9.116v1.815c0 .756.47 1.355 1.197 1.539l1.62.378.093.23-.884 1.41c-.405.636-.304 1.392.24 1.936l1.288 1.29c.544.544 1.299.645 1.934.24l1.418-.876.249.093.377 1.63C7.707 19.53 8.296 20 9.07 20Zm.221-1.668c-.157 0-.23-.074-.258-.212l-.543-2.268a5.263 5.263 0 0 1-1.593-.654l-1.99 1.226c-.11.074-.22.064-.34-.046l-.976-.977c-.101-.101-.11-.212-.027-.332l1.224-1.99a5.756 5.756 0 0 1-.644-1.568l-2.275-.543c-.138-.028-.202-.102-.202-.258V9.327c0-.166.064-.23.202-.267l2.275-.535A6.11 6.11 0 0 1 4.77 6.94L3.554 4.96c-.083-.13-.083-.24.028-.35l.976-.968c.12-.102.212-.12.35-.047l1.98 1.208a6.393 6.393 0 0 1 1.602-.655l.543-2.267c.028-.138.101-.212.258-.212h1.418c.157 0 .23.065.249.212l.552 2.286a5.94 5.94 0 0 1 1.593.645l1.98-1.207c.138-.074.23-.065.34.046l.986.968c.11.1.11.211.027.34L15.221 6.94c.221.396.497.968.635 1.585l2.266.535c.147.037.211.101.211.267v1.383c0 .156-.073.23-.211.258l-2.275.543a6.055 6.055 0 0 1-.635 1.567l1.215 1.991c.074.12.074.23-.027.323l-.976.986c-.12.11-.23.12-.341.046l-1.99-1.226a5.02 5.02 0 0 1-1.583.654l-.552 2.268c-.019.147-.092.212-.249.212H9.291Zm.709-4.83c1.915 0 3.49-1.576 3.49-3.502 0-1.908-1.575-3.484-3.49-3.484-1.915 0-3.5 1.576-3.5 3.484a3.511 3.511 0 0 0 3.5 3.502Zm0-1.576A1.929 1.929 0 0 1 8.085 10c0-1.041.865-1.899 1.915-1.899 1.031 0 1.888.858 1.888 1.899 0 1.05-.857 1.926-1.888 1.926Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgGear;
